import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';
import {
  HP_CE,
  UPPER_TAPPING_ND
} from '../../../../../../../../server/constants';
import { getHpCollectorsEquipmentParams } from '../../../../../../../../server/models/config/thermalProduction/heatpumps/heatpump.model';
import FormSelect from '../../../../../../components/Form/FormSelect';
import Section from '../../../../../../components/Section/Section';
import ConfigsContext from '../../../../../../contexts/ConfigsContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import { getSortedNeeds } from '../../../../../../utils/config.utils';
import { isNull } from '../../../../../../utils/data.utils';
import { getHpUpperModuleName } from '../../../../../../utils/modules.utils';
import NeedBadge from '../components/NeedBadge';

const COLLECTOR_REFS = {
  [HP_CE.EVAP_COLLECTOR]: {
    collector: HP_CE.EVAP_COLLECTOR,
    vdx: HP_CE.VDE,
    upperTapping: HP_CE.UPPER_EVAP_TAPPING
  },
  [HP_CE.COND_COLLECTOR]: {
    collector: HP_CE.COND_COLLECTOR,
    vdx: HP_CE.VDC,
    upperTapping: HP_CE.UPPER_COND_TAPPING
  }
};

const HpsCollectorsEquipmentSection = ({ onFormChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { config } = useContext(ConfigsContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const checkForEquipmentWarning = (checked, defaultValue) =>
    isNull(defaultValue) ? false : defaultValue !== checked;

  const handleCollectorChange = (evt, hpId) => {
    try {
      const { services, heatpumps } = config.ConfigsSst[0].Data;
      const { list } = heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      const { checked, value } = evt.target;
      // on va chercher les références des équipements en fonction des collecteurs
      const { collector, vdx, upperTapping } = COLLECTOR_REFS[value];

      list[index].collectorsEquipment[collector] = checked;
      if (!checked) {
        // on retire Vdx si les collecteurs liés sont absents
        list[index].collectorsEquipment[vdx] = checked;
        if (config.IsModular) {
          // on retire le piquage haut si les collecteurs liés sont absents
          list[index].collectorsEquipment[upperTapping] =
            UPPER_TAPPING_ND.ABSENT;
          // on met à jour le nom du module avec les nouveaux équipements
          list[index].upperModule.name = getHpUpperModuleName(
            list[index],
            services
          );
        }
      }
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleEquipmentChange = (evt, hpId) => {
    try {
      const { services, heatpumps } = config.ConfigsSst[0].Data;
      const { list } = heatpumps;
      const { checked, value } = evt.target;
      const index = list.findIndex((hp) => hp.id === hpId);
      list[index].collectorsEquipment[value] = checked;
      // on met à jour le nom du module avec les nouveaux équipements
      if (config.IsModular) {
        list[index].upperModule.name = getHpUpperModuleName(
          list[index],
          services
        );
      }
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleUpperTappingChange = (value, param, hpId) => {
    try {
      const { services, heatpumps } = config.ConfigsSst[0].Data;
      const { list } = heatpumps;
      const index = list.findIndex((hp) => hp.id === hpId);
      list[index].collectorsEquipment[param.key] = value;
      // on met à jour le nom du module avec les nouveaux équipements
      list[index].upperModule.name = getHpUpperModuleName(
        list[index],
        services
      );
      onFormChange(config);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { services, heatpumps } = config.ConfigsSst[0].Data;
  const { list } = heatpumps;
  const columns = !config.IsModular
    ? Object.values(HP_CE).filter(
        (equipment) =>
          equipment === HP_CE.EVAP_COLLECTOR ||
          equipment === HP_CE.COND_COLLECTOR ||
          equipment === HP_CE.VDE ||
          equipment === HP_CE.VDC
      )
    : Object.values(HP_CE).toSpliced(2, 0, 'upperModuleName');
  return (
    <Section
      title={
        config.IsModular
          ? i18n._('config.hps.hydraulic.upperModules.collectors.equipment')
          : i18n._('config.hps.hydraulic.collectors.equipment')
      }
      level={2}
      open
    >
      <div className='custom-table-wrapper'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th>{i18n._('config.hps.position')}</th>
              <th>{i18n._('config.hps.model')}</th>
              <th>{i18n._('config.hps.type')}</th>
              <th>{i18n._('config.hps.needs')}</th>
              {columns.map((colName) => (
                <th key={'th_ce_' + colName}>
                  {i18n._(`hydraulicConfig.table.th.${colName}`)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list
              .sort((hp1, hp2) => hp1.position - hp2.position)
              .map((hp, index) => {
                const sortedNeeds = getSortedNeeds(hp.needs);
                let params = Object.values(
                  getHpCollectorsEquipmentParams(services, hp, config.IsModular)
                );
                if (config.IsModular) {
                  // on ajoute le nom du module dans le tableau
                  params = params.toSpliced(2, 0, hp.upperModule.name);
                }
                return (
                  <tr key={'row_ce_' + index}>
                    <td>{hp.position}</td>
                    <td>{hp.model}</td>
                    <td>{i18n._(`heatpump.type.${hp.type}`)}</td>
                    <td>
                      <div className='equipment-table-badges'>
                        {sortedNeeds.map((need) => (
                          <NeedBadge
                            key={'badge_' + index + need}
                            need={need}
                          />
                        ))}
                      </div>
                    </td>
                    {params.map((param) => {
                      const checked = hp.collectorsEquipment?.[param.key];
                      const warning = checkForEquipmentWarning(
                        checked,
                        param.default
                      );
                      const checkboxId = 'hp_ce_' + index + '_' + param.key;
                      if (
                        param.key === HP_CE.EVAP_COLLECTOR ||
                        param.key === HP_CE.COND_COLLECTOR
                      ) {
                        return (
                          <td key={'col_ce_' + param.key}>
                            <Form.Check
                              type='checkbox'
                              label={i18n._(
                                `hydraulicConfig.table.td.${param.key}`,
                                {
                                  position: hp.position
                                }
                              )}
                              value={param.key}
                              onChange={(evt) =>
                                handleCollectorChange(evt, hp.id)
                              }
                              checked={!!checked}
                              disabled={!param.enabled}
                              id={'hps_ce_' + index + '_' + param.key}
                            />
                          </td>
                        );
                      } else if (
                        param.key === HP_CE.VDE ||
                        param.key === HP_CE.VDC
                      ) {
                        return (
                          <td key={'col_ce_' + param.key}>
                            <div
                              data-tooltip-content={
                                warning
                                  ? checked
                                    ? i18n._('equipment.notChecked.warning')
                                    : i18n._('equipment.checked.warning')
                                  : null
                              }
                              data-tooltip-id={'error_' + checkboxId}
                              style={{ width: 'fit-content' }}
                            >
                              <Form.Check
                                type='checkbox'
                                label={i18n._(
                                  `hydraulicConfig.table.td.${param.key}`,
                                  {
                                    position: hp.position
                                  }
                                )}
                                value={param.key}
                                onChange={(evt) =>
                                  handleEquipmentChange(evt, hp.id)
                                }
                                checked={!!checked}
                                className={warning ? 'warning' : ''}
                                disabled={
                                  param.key === HP_CE.VDE
                                    ? config.IsModular &&
                                      !hp.collectorsEquipment.evapCollector
                                    : config.IsModular &&
                                      !hp.collectorsEquipment.condCollector
                                }
                                id={'hps_ce_' + index + '_' + param.key}
                              />
                              {warning &&
                                createPortal(
                                  <Tooltip
                                    id={'error_' + checkboxId}
                                    place='bottom'
                                    className='error-tooltip'
                                    arrowColor='#f04460'
                                    opacity={1}
                                  />,
                                  document.body
                                )}
                            </div>
                          </td>
                        );
                      } else if (
                        param.key === HP_CE.UPPER_EVAP_TAPPING ||
                        param.key === HP_CE.UPPER_COND_TAPPING
                      ) {
                        return (
                          <td key={'col_ce_' + index + '_' + param.key}>
                            <FormSelect
                              param={param}
                              value={hp.collectorsEquipment[param.key]}
                              onChange={(value, event) =>
                                handleUpperTappingChange(value, param, hp.id)
                              }
                              disabled={
                                param.key === HP_CE.UPPER_EVAP_TAPPING
                                  ? !hp.collectorsEquipment.evapCollector
                                  : !hp.collectorsEquipment.condCollector
                              }
                            />
                          </td>
                        );
                      } else {
                        return (
                          <td key={'col_ce_' + index + '_' + param}>{param}</td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Section>
  );
  //#endregion
};

export default HpsCollectorsEquipmentSection;
